import type { IItem } from '@Models/filters/item.model';

export interface IFilterStore {
  count: null | number;
  items: IItem[];
}

export interface IRequest {
  aggregations: string[];
  termFilters: { field: string; value: string[] }[];
  fullTextFilters: { field: string; value: string }[];
  numberFilters: { field: string; lowerBound?: string; upperBound?: string }[];
  dateRangeFilters: { field: string; lowerBound?: string; upperBound?: string }[];
  multiTermFilters?: { field: string; value: string[] }[];
  limit: number;
  orderBy: string;
  orderByField: string;
  offset: number;
  query?: string;
}

export interface ICategory {
  category: string;
  code: string;
  dictKey: number;
  key: number;
  value: string;
}

export interface IFilterResponse {
  aggregations: IAggregations[];
  count: number;
  items: IItem[];
}

export interface IAggregations {
  counts: IAggregationCount[];
  name: string;
  type: string;
}

export interface IAggregationCount {
  param: string;
  count: number;
}

export interface IAdditionalFilter {
  name: string;
  nodeName: string;
  type: string;
}

export interface IAdditionalSingleFilterValue {
  key: string;
  nodeName: string;
  value: any;
  valueType?: string;
}

export type TQueryWithFilters = Record<string, string>;

export enum EMainCategories {
  'REAL_ESTATE' = 1,
  'MOVABLE' = 2,
  'REAL_ESTATE_NAME' = 'Nieruchomości',
  'MOVABLE_NAME' = 'Ruchomości',
  'ALL' = 'Wszystkie'
}

export enum EFilterInputsName {
  MAIN_CATEGORY = 'mainCategory'
}

export enum EAuctionCategories {
  'REAL_ESTATE' = 'REAL_ESTATE',
  'MOVABLE' = 'MOVABLE'
}

export enum EFilterSeparator {
  SEPARATOR = '%'
}

export enum EAdditionalFilter {
  TERM = 'term'
}

export enum EFilterTypes {
  TERM_FILTERS = 'termFilters',
  NUMBER_FILTERS = 'numberFilters',
  QUERY = 'query',
  FULL_TEXT_FILTERS = 'fullTextFilters',
  DATE_RANGE_FILTERS = 'dateRangeFilters',
  ORDER_BY = 'orderBy',
  ORDER_BY_FIELD = 'orderByField',
  LIMIT = 'limit',
  OFFSET = 'offset'
}

export enum EFilterFlags {
  CREATED = 'dateCreated',
  START_AT = 'startAuctionAt',
  OPENING_VALUE = 'openingValue',
  ESTIMATE = 'estimate',
  POPULARITY = 'uniqueViews',
  ASC = 'ASC',
  DESC = 'DESC',
  SCORE = '_score',
  NOTES = 'notes',
  SORT = 'sort'
}

export interface ISortBar {
  label: string;
  value: number | string | any;
}

export interface HighlightI {
  key: string;
}
